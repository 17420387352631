module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"./src/images/favicon.png","icons":[{"src":"./src/images/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"./src/images/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"./src/images/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"./src/images/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"./src/images/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"./src/images/icon-256x256.png","sizes":"256x4256","type":"image/png"},{"src":"./src/images/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"./src/images/icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"54327fe33d491a0982af2b70fb5f3148"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#A48661","showSpinner":false},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MBJ8CH7","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"enableWebVitalsTracking":true,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
