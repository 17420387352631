import { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { getLocalStorageData, setLocalStorageData, unsetLocalStorageData } from '../helpers/localStorage';

/**
 * @description Hook to get customer data
 * @returns {Object} Customer data
 */
export function useCustomerData() {
  const key = 'customerData';

  const [cookies, setCookie, removeCookie] = useCookies([key]);
  const [rememberLogin, setRememberLogin] = useState(false);

  useEffect(() => {
    let storageValue = null;

    try {
      // Local storage
      const strCustomer = getLocalStorageData(key);

      if (strCustomer) {
        storageValue = JSON.parse(strCustomer);
        setRememberLogin(true);
        setCookie(key, storageValue, {
          path: '/',
          expires: 0
        });
      }
    } catch {
      storageValue = null;
    }
  }, [key, setCookie]);

  // Set customer data from cookies key
  const customerData = cookies?.[key] || null;

  // Set customer data from local storage key
  const setCustomerData = useCallback(
    (newValue, remember) => {
      if (newValue) {
        const serializedNewValue = JSON.stringify(newValue);

        setRememberLogin(remember);

        if (remember) {
          setLocalStorageData(key, serializedNewValue);
        } else {
          unsetLocalStorageData(key);
        }

        setCookie(key, newValue, {
          path: '/',
          expires: 0
        });
      } else {
        setRememberLogin(false);

        try {
          unsetLocalStorageData(key);
        } catch (err) {
          console.log(err);
        }

        removeCookie(key, { path: '/' });
      }

      return newValue;
    },
    [removeCookie, setCookie]
  );

  return { customerData, setCustomerData, rememberLogin };
}
