exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../../../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-pages-account-messages-tsx": () => import("./../../../src/pages/account/messages.tsx" /* webpackChunkName: "component---src-pages-account-messages-tsx" */),
  "component---src-pages-account-orders-tsx": () => import("./../../../src/pages/account/orders.tsx" /* webpackChunkName: "component---src-pages-account-orders-tsx" */),
  "component---src-pages-account-settings-tsx": () => import("./../../../src/pages/account/settings.tsx" /* webpackChunkName: "component---src-pages-account-settings-tsx" */),
  "component---src-pages-bread-financing-index-tsx": () => import("./../../../src/pages/bread-financing/index.tsx" /* webpackChunkName: "component---src-pages-bread-financing-index-tsx" */),
  "component---src-pages-bread-pay-index-tsx": () => import("./../../../src/pages/bread-pay/index.tsx" /* webpackChunkName: "component---src-pages-bread-pay-index-tsx" */),
  "component---src-pages-cart-index-tsx": () => import("./../../../src/pages/cart/index.tsx" /* webpackChunkName: "component---src-pages-cart-index-tsx" */),
  "component---src-pages-checkout-index-tsx": () => import("./../../../src/pages/checkout/index.tsx" /* webpackChunkName: "component---src-pages-checkout-index-tsx" */),
  "component---src-pages-details-index-tsx": () => import("./../../../src/pages/details/index.tsx" /* webpackChunkName: "component---src-pages-details-index-tsx" */),
  "component---src-pages-forgot-password-index-tsx": () => import("./../../../src/pages/forgot-password/index.tsx" /* webpackChunkName: "component---src-pages-forgot-password-index-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-register-index-tsx": () => import("./../../../src/pages/register/index.tsx" /* webpackChunkName: "component---src-pages-register-index-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-content-tsx": () => import("./../../../src/templates/content.tsx" /* webpackChunkName: "component---src-templates-content-tsx" */),
  "component---src-templates-epi-products-tsx": () => import("./../../../src/templates/epi-products.tsx" /* webpackChunkName: "component---src-templates-epi-products-tsx" */),
  "component---src-templates-hotel-tsx": () => import("./../../../src/templates/hotel.tsx" /* webpackChunkName: "component---src-templates-hotel-tsx" */),
  "component---src-templates-products-tsx": () => import("./../../../src/templates/products.tsx" /* webpackChunkName: "component---src-templates-products-tsx" */),
  "component---src-templates-store-tsx": () => import("./../../../src/templates/store.tsx" /* webpackChunkName: "component---src-templates-store-tsx" */),
  "slice---src-components-layouts-common-footer-jsx": () => import("./../../../src/components/layouts/common/footer.jsx" /* webpackChunkName: "slice---src-components-layouts-common-footer-jsx" */),
  "slice---src-components-layouts-common-header-jsx": () => import("./../../../src/components/layouts/common/header.jsx" /* webpackChunkName: "slice---src-components-layouts-common-header-jsx" */)
}

