/**
 * @description Get cart ID
 * @returns {string} Cart ID
 */
export const getCartId = (customerId) => {
  if (typeof window !== 'undefined') {
    if (!customerId) {
      return localStorage.getItem('cartId');
    }
    return getExistingCustomerCartId(customerId);
  }
};

export const getExistingCustomerCartId = (customerId) => {
  const customerCartData = localStorage.getItem('customerCartData');
  const parsedData = customerCartData ? JSON.parse(customerCartData) : [];
  const cartId = parsedData.find((el) => el.customerId === customerId)?.cartId || null;
  return cartId;
};

/**
 * @description Set cart ID
 * @param {string} cartId
 * @returns {void} Set cart ID
 */
export const setCartId = (cartId, customerId) => {
  if (typeof window !== 'undefined' && cartId !== null) {
    if (customerId) {
      let existingCustomerCartData = JSON.parse(localStorage.getItem('customerCartData') || '[]');

      // check if existing customer ID is already present in the records
      const isMatchFound = existingCustomerCartData.find((el) => el.customerId === customerId);
      if (!isMatchFound) {
        // push new entry
        existingCustomerCartData.push({ cartId, customerId });
        localStorage.setItem('customerCartData', JSON.stringify(existingCustomerCartData));
      } else {
        //upate the existing cart ID
        const index = existingCustomerCartData.indexOf(isMatchFound);
        isMatchFound['cartId'] = cartId;
        existingCustomerCartData[index] = isMatchFound;
        localStorage.setItem('customerCartData', JSON.stringify(existingCustomerCartData));
      }
    } else {
      localStorage.setItem('cartId', cartId);
    }
  } else {
    localStorage.removeItem('cartId');
  }
};

/**
 * @description Get cart item number
 * @param {Object} line_items
 * @returns {number} Cart item number
 */
export const getCartItemNumber = (line_items) => {
  const physical_items = line_items?.physical_items?.filter((item) => item.list_price !== 0) || null;
  const digital_items = line_items?.digital_items?.filter((item) => item.list_price !== 0) || null;
  const custom_items = line_items?.custom_items?.filter((item) => item.list_price !== 0) || null;
  const gift_certificates = line_items?.gift_certificates?.filter((item) => item.list_price !== 0) || null;

  const numberItems =
    physical_items?.length ||
    0 + digital_items?.length ||
    0 + custom_items?.length ||
    0 + gift_certificates?.length ||
    0;

  return numberItems;
};
