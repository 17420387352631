import axios from 'axios';

/**
 * Add cart line items
 *
 * Source: https://developer.bigcommerce.com/api-reference/b3A6MzU5MDQxOTQ-add-cart-line-items
 *
 * @returns {Promise} Promise object represents the result of the request
 */
export const addCartLineItems = async ({ cartId, lineItems, giftCertificates, customItems }) => {
  const endpoint = `/api/bigcommerce/carts/cart-items/add-cart-line-items?cart_id=${cartId}&include=line_items.physical_items.options,line_items.digital_items.options,redirect_urls,promotions.banners`;

  const body = {
    line_items: lineItems,
    custom_items: customItems,
    gift_certificates: giftCertificates
  };

  return await axios
    .post(endpoint, body)
    .then((res) => res.data)
    .catch((err) => err);
};

/**
 * Updates an existing, single line item in the cart.
 *
 * Source: https://developer.bigcommerce.com/api-reference/b3A6MzU5MDQxOTY-update-cart-line-item
 *
 * @returns {Promise} Promise object represents the result of the request
 */
export const updateCartLineItem = async ({ cartId, itemId, lineItem }) => {
  const endpoint = `/api/bigcommerce/carts/cart-items/update-cart-line-item?cart_id=${cartId}&item_id=${itemId}&include=line_items.physical_items.options,line_items.digital_items.options,redirect_urls,promotions.banners`;

  const body = {
    line_item: lineItem
  };

  return await axios
    .put(endpoint, body)
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err));
};

/**
 * Deletes line item/s in the cart.
 *
 * Source: https://developer.bigcommerce.com/api-reference/b3A6MzU5MDQxOTc-delete-cart-line-item
 *
 * @returns {Promise} Promise object represents the result of the request
 */
export const deleteCartLineItem = async ({ cartId, itemId }) => {
  const endpoint = `/api/bigcommerce/carts/cart-items/delete-cart-line-item?cart_id=${cartId}&item_id=${itemId}&include=line_items.physical_items.options,line_items.digital_items.options,redirect_urls,promotions.banners`;

  return await axios
    .delete(endpoint)
    .then((res) => Promise.resolve(res))
    .catch((err) => Promise.reject(err));
};
