// Request
export const REQUEST_TIMEOUT = process.env.REQUEST_TIMEOUT;
export const ACCEPT_HEADER = process.env.ACCEPT_HEADER;
export const ACCESS_CONTROL_ALLOW_HEADERS = process.env.ACCESS_CONTROL_ALLOW_HEADERS;
export const CORS_ORIGIN = process.env.CORS_ORIGIN;

// Site URLs
export const SITE_URL =
  typeof window !== 'undefined' && process.env.NODE_ENV === 'production'
    ? window.location.protocol + '//' + window.location.hostname
    : process.env.GATSBY_PUBLIC_DEVELOPMENT_SITE_URL;
export const SITE_PRODUCTION_URL = process.env.GATSBY_PUBLIC_PRODUCTION_SITE_URL;
export const SITE_INTERNAL_URL = process.env.GATSBY_PUBLIC_INTERNAL_SITE_URL;
export const SITE_PREPARATION_URL = process.env.GATSBY_PUBLIC_PREPARATION_SITE_URL;
export const SITE_STORE_URL = process.env.GATSBY_PUBLIC_PRODUCTION_STORE_SITE_URL;
export const SITE_UK_STORE_URL = process.env.GATSBY_PUBLIC_PRODUCTION_UK_SITE_URL;
export const SITE_STAGING_URL = process.env.GATSBY_PUBLIC_STAGING_SITE_URL;

// Maps
export const GOOGLE_MAP_API_KEY = process.env.GATSBY_PUBLIC_GOOGLE_MAP_API_KEY;

// BigCommerce
export const BIGCOMMERCE_API_HOSTNAME = process.env.GATSBY_PUBLIC_BIGCOMMERCE_API_HOSTNAME;
export const BIGCOMMERCE_API_ALLOWED_METHODS = process.env.BIGCOMMERCE_API_ALLOWED_METHODS;
export const GATSBY_PUBLIC_BIGCOMMERCE_API_STORE_HASH = process.env.GATSBY_PUBLIC_BIGCOMMERCE_API_STORE_HASH;
export const GATSBY_PUBLIC_BIGCOMMERCE_CDN_HOSTNAME = process.env.GATSBY_PUBLIC_BIGCOMMERCE_CDN_HOSTNAME;

// API endpoints
export const EPISERVER_API_REQUEST_LOCATION =
  process.env.OPTIMIZELY_API_HOSTNAME +
  process.env.GATSBY_PUBLIC_OPTIMIZELY_API_REQUEST_LOCATION +
  '?lang=en-us&market=US';

// SearchSpring
export const SEARCHSPRING_SITE_ID = process.env.SEARCHSPRING_SITE_ID;
export const SEARCHSPRING_SITE_API_URL = process.env.SEARCHSPRING_SITE_API_URL;

export const USER_LOCATION_API = 'https://api.ipgeolocation.io/ipgeo';
export const USER_LOCATION_API_KEY = '69b25a6e173d4b0e893769a635d9d345';

// Numerical Values
export const ON_ERROR_RETRY_COUNT = 5;
export const REVALIDATION_INTERVAL = 3000;
export const CHANNEL_ID = 1195235;
export const BED_PDP_IMAGE_WIDTH_RESOLUTION = '1920w';
export const LOGOUT_IFRAME_URL = 'https://store.duxiana.com/login.php?action=logout';
export const PRODUCTS_PAGE = 'PRODUCTS_PAGE';
export const CART_PAGE = 'CART_PAGE';
export const MAX_ANCHOR_HEADINGS = 3;
