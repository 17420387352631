import { Script } from 'gatsby';
import { Layout } from './src/components/layouts';
import { AppProvider } from './src/contexts/AppProvider';
import { PageProvider } from './src/contexts/PageProvider';
import React from 'react';
import type { GatsbyBrowser, PageProps } from 'gatsby';

/**
 * @description Wrap the root element with some context providers
 * @param {*} element
 * @returns {React.Element} The root element with the context providers
 */
export const wrapRootElement = ({ element }) => <AppProvider>{element}</AppProvider>;

const PageAppWrapper = ({ children, props }: { children: React.ReactElement; props: PageProps }) => {
  return (
    <PageProvider {...props}>
      <Layout>{children}</Layout>
      <Script defer={true} src={`${process.env.GATSBY_PUBLIC_BREADPAY_SDK}?v=${new Date().getTime()}`} />
      <Script defer={true} src={process.env.GATSBY_PUBLIC_BREADPAY_FINANCING_SDK} />
      <Script defer={true} src="/Bundles/gtm.js" />
      <Script defer={true} src="/Bundles/HeaderScripts.js" data-script="animation" />
      {process.env.GATSBY_PUBLIC_ENV === 'stage' && <Script defer={true} src="/Bundles/MarkerIoScript.js" />}
    </PageProvider>
  );
};

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, ...props }) => (
  <PageAppWrapper {...props}>{element}</PageAppWrapper>
);

/**
 * @description Disable the scroll restoration
 * getSavedScrollPosition param - stores the scroll position in sessionstorage while navigating btw pages
 * @returns {boolean} false
 */
export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  // please keep this settimeout function to reflect the scroll position values while navigating btw pages
  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo({ left: 0, top: 0, behavior: 'instant' }), 500);
  } else {
    const savedPosition = getSavedScrollPosition(location);
    window.setTimeout(() => window.scrollTo(...(savedPosition || [0, 0])), 500);
  }
  return false;
};

export const onRouteUpdate = () => {
  const loadScript = () => {
    const URL = '/Bundles/EpiCrateScripts.js';
    const existingScript = document.querySelector('[data-script]');
    if (existingScript) {
      existingScript.remove();
    }
    const script = document.createElement('script');
    const body = document.getElementsByTagName('body')[0];
    script.src = URL;
    script.type = 'application/javascript';
    script.defer = true;
    script.dataset.script = 'animation';
    body.appendChild(script);
  };

  loadScript();

  window.addEventListener('hashchange', () => {
    loadScript();
  });
};
