import axios from 'axios';
import { useMemo, useState } from 'react';
import BigCommerce from '../../../libs/bigcommerce';

/**
 * @description Returns a stores cart.
 * @param {*} cartId
 * @returns {Promise} Promise object represents the result of the request
 */
export const useCart = async ({ cartId }) => {
  const endpoint = `/api/bigcommerce/carts/cart/get-cart?cart_id=${cartId}&include=line_items.physical_items.options,line_items.digital_items.options,redirect_urls,promotions.banners`;

  return await axios
    .get(endpoint)
    .then((res) => Promise.resolve(res))
    .catch((err) => Promise.reject(err));
};

/**
 * @description Creates a cart
 * @param {*} customerId
 * @param {*} channelId
 * @param {*} lineItems
 * @param {*} customItems
 * @param {*} giftCertificates
 * @param {*} currency
 * @param {*} channelId
 * @param {*} locale
 * @returns {Promise} Promise object represents the result of the request
 */
export const createCart = async ({
  customerId,
  lineItems,
  customItems,
  giftCertificates,
  currency,
  channelId = 1,
  locale = 'en'
}) => {
  const endpoint = `/api/bigcommerce/carts/cart/create-cart?include=line_items.physical_items.options,line_items.digital_items.options,redirect_urls,promotions.banners`;

  const body = {
    customer_id: customerId,
    channel_id: channelId,
    line_items: lineItems,
    custom_items: customItems,
    gift_certificates: giftCertificates,
    currency: currency,
    locale: locale
  };

  return await axios
    .post(endpoint, body)
    .then((res) => res.data)
    .catch((err) => err);
};

/**
 * @description Updates a carts `customer_id`.
 * @param {*} cartId
 * @param {*} customerId
 * @returns {Promise} Promise object represents the result of the request
 */
export const updateCustomerId = async ({ cartId, customerId }) => {
  const endpoint = `/api/bigcommerce/carts/cart/update-customer-id?cart_id=${cartId}`;
  const body = {
    customer_id: customerId
  };

  return await axios
    .put(endpoint, body)
    .then((res) => res.data)
    .catch((err) => err);
};

/**
 * @description Deletes a cart. Once a cart has been deleted it can not be recovered.
 * @param {*} cartId
 * @returns {Promise} Promise object represents the result of the request
 */
export const deleteCart = async ({ cartId }) => {
  const endpoint = `/api/bigcommerce/carts/cart/delete-cart?cart_id=${cartId}`;

  return await axios
    .delete(endpoint)
    .then((res) => res.data)
    .catch((err) => err);
};

/**
 * @desciprtion Creates a cart redirect url.
 * @param {*} cartId
 * @returns {Promise} Promise object represents the result of the request
 */
export const createCartRedirectUrl = async ({ cartId, customerId }) => {
  const endpoint = `/api/bigcommerce/carts/cart-redirect-items/create-cart-redirect-url?cart_id=${cartId}`;
  return await axios
    .post(endpoint, { customerId })
    .then((res) => res.data)
    .catch((err) => err);
};

/**
 * @description Get the cart ID
 * @returns {string} Cart ID
 */
export const useGetCartId = () => {
  const [cartId, setCartId] = useState(null);

  useMemo(() => {
    setCartId(localStorage.getItem('cartId'));
  }, []);

  return cartId;
};

/**
 * @description Set cart ID
 * @param {*} cartId
 * @returns {string} Cart ID
 */
export const useSetCartId = (cartId) => {
  useMemo(() => {
    if (cartId) {
      localStorage.setItem('cartId', cartId);
    } else {
      localStorage.removeItem('cartId');
    }
  }, [cartId]);

  return;
};

/**
 * @description Get cart item number
 * @param {*} line_items
 * @returns {number} Cart item numbers
 */
export const useGetCartItemNumber = (line_items) => {
  const [numberItems, setNumberItems] = useState(0);

  useMemo(() => {
    let initialNumberItems =
      line_items?.physical_items?.length ||
      0 + line_items?.digital_items?.length ||
      0 + line_items?.custom_items?.length ||
      0 + line_items?.gift_certificates?.length ||
      0;

    setNumberItems(initialNumberItems);
  }, [line_items]);

  return numberItems;
};

/**
 * @description Fetch the checkout information from the server
 * @param {*} checkoutId
 * @returns {Promise} Promise object represents the result of the request
 */
export const useFetchCheckout = async (checkoutId) => {
  let result = BigCommerce.get(
    `/api/get-checkout?checkout_id=${checkoutId}&include=cart.line_items.physical_items.options,cart.line_items.digital_items.options,consignments.available_shipping_options,promotions.banners`
  );

  return await result.then((res) => res.data).catch((err) => err);
};
