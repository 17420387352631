import axios from 'axios';

/**
 * Adds a Coupon Code to `Checkout`.
 *
 * Source: https://developer.bigcommerce.com/api-reference/b3A6MzU5MDQ0Nzk-add-coupon-to-checkout
 *
 * @returns {Promise} Promise object represents the result of the request
 */
export const addCouponToCheckout = async ({ checkoutId, couponCode }) => {
  const endpoint = `/api/bigcommerce/checkouts/checkout-coupons/add-coupon-to-checkout?checkout_id=${checkoutId}`;

  const body = {
    coupon_code: couponCode
  };

  return await axios
    .post(endpoint, body)
    .then((res) => Promise.resolve(res))
    .catch((err) => Promise.reject(err));
};

/**
 * Deletes a `Coupon Code` from `Checkout`.
 *
 * Source: https://developer.bigcommerce.com/api-reference/b3A6MzU5MDQ0ODA-delete-checkout-coupon
 *
 * @returns {Promise} Promise object represents the result of the request
 */
export const deleteCheckoutCoupon = async ({ checkoutId, couponCode }) => {
  const endpoint = `/api/bigcommerce/checkouts/checkout-coupons/delete-checkout-coupon?checkout_id=${checkoutId}&coupon_code=${couponCode}`;

  return await axios
    .delete(endpoint)
    .then((res) => Promise.resolve(res))
    .catch((err) => Promise.reject(err));
};
