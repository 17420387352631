import { createContext, useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { CHANNEL_ID } from '../constants/global';
import { createCart, createCartRedirectUrl, updateCustomerId } from '../hooks/bigcommerce/cart/useCart';
import { addCartLineItems, deleteCartLineItem, updateCartLineItem } from '../hooks/bigcommerce/cart/useCartItems';
import { getCheckout } from '../hooks/bigcommerce/checkout/useCheckout';
import {
  addConsignmentToCheckout,
  updateCheckoutConsignment
} from '../hooks/bigcommerce/checkout/useCheckoutConsignment';
import { addCouponToCheckout, deleteCheckoutCoupon } from '../hooks/bigcommerce/checkout/useCheckoutCoupon';
import { useCustomerData } from '../hooks/useCustomerData';
import { getCartId, setCartId } from '../utils/cart';

const initialState = {
  addingToCart: false,
  loading: false,
  id: null
};

// Contexts
const AppContext = createContext();

/**
 * @description Create a new context for the application
 * @param {*} props
 * @returns {React.Element} The context provider
 */
export const AppProvider = (props) => {
  const [cartData, setCartData] = useState(initialState);

  // Hooks
  const { customerData, setCustomerData, rememberLogin } = useCustomerData();

  /**
   * @description Show notifications
   * @param {string} text
   * @returns {void} Show notifications
   */
  const showNotification = (text) => toast(text);

  /**
   * @description Set error notification
   * @param {string} error
   * @param {Boolean} notify
   * @returns {void} Set error notification
   */
  const setErrorNotification = (error, notify = true) => {
    setCartData({ ...cartData, loading: false, cartError: error });

    if (notify) showNotification('Failed! Try again.', 'error');

    return;
  };

  const resetCart = () => {
    setCartData(initialState);
  };

  const assignIdToCart = (cartId) => {
    setCartData({ ...cartData, id: cartId });
  };

  /**
   * @description Handle refreshing checkout information
   * @param {Object} response
   * @returns {Object} The checkout data
   */
  const refreshCheckout = async (data) => {
    setCartData({
      ...cartData,
      loading: false,
      ...data
    });

    return data;
  };

  /**
   * @description Handle fetching checkout information
   * @param {string} checkoutId
   * @returns {Promise<void>} The checkout data
   */
  const fetchCheckout = async (checkoutId) => {
    let result = await getCheckout({ checkoutId });
    if (result.status === 200) {
      refreshCheckout(result.data);
    } else {
      setCartData({ ...cartData, loading: false });
    }

    return result;
  };

  /**
   * @description Handle loading checkout information
   * @returns {Promise<void>} The checkout data
   */
  const loadCheckout = async (customerId) => {
    let cartId = getCartId(customerId);
    if (cartId && cartId?.length > 0) {
      setCartData({ ...cartData, loading: true });
      await fetchCheckout(cartId);
    } else {
      resetCart();
    }
    return cartId;
  };

  // Handle fetching prices
  useEffect(() => {
    loadCheckout(customerData?.id);
  }, []);

  /**
   * @description Handle adding an item to the cart
   * @param {Array} line_item
   * @returns {Promise<void>} The checkout data
   */
  const addToCart = async (line_item) => {
    let line_items = [],
      result = {},
      cartId = cartData.id,
      channelId = CHANNEL_ID;

    line_items.push(line_item);

    setCartData({ ...cartData, addingToCart: line_item.product_id });

    if (!cartId) {
      if (customerData) {
        result = await createCart({ lineItems: line_items, channelId, customerId: customerData.id });
      } else {
        result = await createCart({ lineItems: line_items, channelId });
      }
    } else {
      result = await addCartLineItems({ cartId, lineItems: line_items });
    }
    if (result.data) {
      setCartData({
        ...cartData,
        id: result.data.id
      });

      var checkoutId = result.data.id;

      setCartId(checkoutId, customerData?.id);
      fetchCheckout(checkoutId);
      showNotification('Item added to cart!', 'success');

      return true;
    } else {
      if (result?.error && result?.error?.title && checkForItemOutOfStock(result.error.title)) {
        return showItemOutOfStock();
      }
      setErrorNotification(result);
      return false;
    }
  };

  /**
   * @description Handle updating an item in the cart
   * @param {string} itemId
   * @param {string} data
   * @returns {Promise<void>} The checkout data
   */
  const updateItemInCart = async (itemId, data) => {
    let cartId = cartData.id,
      result = {};
    result = await updateCartLineItem({ cartId: cartId, itemId: itemId, lineItem: data });

    if (result.data) {
      fetchCheckout(cartId);
      showNotification('Quantity changed.', 'success');
    } else {
      if (result?.error && result?.error?.title && checkForItemOutOfStock(result.error.title)) {
        return showItemOutOfStock();
      }
      setErrorNotification(result);
      return false;
    }

    return result;
  };

  /**
   * Handle removing an item from the cart
   *
   * @param {string} itemId
   */
  const removeItemFromCart = async (itemId) => {
    try {
      let cartId = cartData.id;
      setCartData({
        ...cartData,
        loading: true
      });
      let result = await deleteCartLineItem({ cartId, itemId });
      setCartData({
        ...cartData,
        loading: false
      });
      if (!result.data) {
        return resetCart();
      }
      fetchCheckout(cartId);
      showNotification('Item removed from cart.', 'success');
    } catch (error) {
      setCartId(null);
      setCartData(initialState);
    }
  };

  /**
   * Handle updating the cart item quantity
   *
   * @param {Object} item
   * @param {string} action
   */
  const updateCartItemQuantity = async (item, action) => {
    const newQuantity = item.quantity + (action === 'minus' ? -1 : 1);

    setCartData({ ...cartData, loading: true, updatingItem: item.id });

    if (newQuantity < 1) {
      return removeItemFromCart(item.id);
    }

    updateItemInCart(item.id, {
      ...item,
      loading: false,
      quantity: newQuantity
    });
  };

  const getCheckoutLineItems = () => {
    let lineItems = [];

    for (const item of cartData.cart.line_items.physical_items) {
      lineItems.push({
        quantity: item.quantity,
        item_id: item.id
      });
    }
    for (const item of cartData.cart.line_items.gift_certificates) {
      lineItems.push({
        quantity: item.quantity,
        item_id: item.id
      });
    }
    for (const item of cartData.cart.line_items.digital_items) {
      lineItems.push({
        quantity: item.quantity,
        item_id: item.id
      });
    }
    for (const item of cartData.cart.line_items.custom_items) {
      lineItems.push({
        quantity: item.quantity,
        item_id: item.id
      });
    }
    return lineItems;
  };

  const addConsignmentCheckout = async (address) => {
    try {
      let checkoutId = cartData.id;

      let lineItems = getCheckoutLineItems();
      let result = await addConsignmentToCheckout({ checkoutId, address, lineItems });

      refreshCheckout(result.data);
      return true;
    } catch (error) {
      setErrorNotification(error);
    }
  };

  const updateConsignmentCheckout = async (shipping_option_id) => {
    try {
      if (cartData?.consignments?.length > 0) {
        let checkoutId = cartData.id;
        let consignmentId = cartData?.consignments[0].id;
        let result = await updateCheckoutConsignment({
          checkoutId,
          consignmentId,
          shippingOptionId: shipping_option_id
        });

        refreshCheckout(result.data);
        return true;
      }
    } catch (error) {
      setErrorNotification(error);
    }
  };

  /**
   * Handle adding coupon to checkout = cart
   *
   * @param {string} code
   */
  const addCouponCode = async (code) => {
    try {
      let checkoutId = cartData.id;
      let result = await addCouponToCheckout({ checkoutId: checkoutId, couponCode: code });

      if (result.data?.error) {
        showNotification('Coupon invalid.', 'error');
        return false;
      }
      refreshCheckout(result.data);
      showNotification('Coupon is added to Checkout.', 'success');
      return true;
    } catch (error) {
      setErrorNotification(error);
    }
  };

  const removeCouponCode = async (code) => {
    try {
      let checkoutId = cartData.id;
      let result = await deleteCheckoutCoupon({ checkoutId: checkoutId, couponCode: code });

      refreshCheckout(result.data);
      showNotification('Coupon is removed.', 'success');
    } catch (error) {
      setErrorNotification(error);
    }
  };

  const createCheckoutUrl = async () => {
    try {
      let cartId = cartData.id;
      let result = await createCartRedirectUrl({ cartId, customerId: customerData?.id });

      setCartData({
        ...cartData,
        checkout_url: result.checkout_url
      });
      return result;
    } catch (error) {
      showNotification("Can't create checkout url", 'error');
    }
  };

  const updateCartCustomerId = async (customerId) => {
    try {
      let cartId = cartData.id;
      let result = await updateCustomerId({ cartId, customerId });

      setCartData({
        ...cartData,
        customer_id: result?.data?.data?.customer_id
      });
      return true;
    } catch (error) {
      console.error('Error updating customer ID', error.message);
    }
  };

  const checkForItemOutOfStock = (messageText) => {
    const isOutOfStock = messageText.toLowerCase().includes('does not have sufficient stock');
    return isOutOfStock;
  };

  const showItemOutOfStock = () => {
    const errorMessage = 'Product is out of stock';
    setCartData({ ...cartData, loading: false, cartError: errorMessage });
    showNotification(errorMessage);
    return;
  };

  return (
    <>
      <AppContext.Provider
        value={{
          customerData,
          setCustomerData,
          rememberLogin,
          cartData,
          resetCart,
          assignIdToCart,
          addToCart,
          updateItemInCart,
          removeItemFromCart,
          updateCartItemQuantity,
          addConsignmentCheckout,
          updateConsignmentCheckout,
          addCouponCode,
          removeCouponCode,
          createCheckoutUrl,
          updateCartCustomerId,
          loadCheckout
        }}
      >
        {props.children}
      </AppContext.Provider>
    </>
  );
};

export const useAppContext = () => useContext(AppContext);
