export const ANCHOR_CLASSES = [
  '',
  'topleft',
  'topcenter',
  'topright',
  'middleleft',
  'middlecenter',
  'middleright',
  'bottomleft',
  'bottomcenter',
  'bottomright'
];

export const HERO_IMAGE_DARK_THEMES = ['theme-white-text', 'theme-almost-black'];

export const sortSelectForSearchSpringItems = [
  {
    value: 'relevance=desc',
    label: 'Best Match'
  },
  {
    value: 'total_sold=desc',
    label: 'Most Popular'
  },
  {
    value: 'sortable_date_created=desc',
    label: 'Newest'
  },
  {
    value: 'name=asc',
    label: 'Name: A - Z'
  },
  {
    value: 'name=desc',
    label: 'Name: Z - A'
  },
  {
    value: 'calculated_price=asc',
    label: 'Price: Low to High'
  },
  {
    value: 'calculated_price=desc',
    label: 'Price: High to Low'
  }
];

// 5 minutes for testing
export const expireTimeOfCountrySelection = 60 * 60 * 24 * 7;
