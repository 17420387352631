import { createContext, useContext, useEffect, useState } from 'react';

const PageContext = createContext({});

export const PageProvider = (props) => {
  const [isSSR, setIsSSR] = useState(true);
  const [pageContext, setPageContext] = useState(props.pageContext);
  const [pageExternalImages, setPageExternalImages] = useState([]);
  const [showCartDropdown, setShowCartDropdown] = useState(false);

  useEffect(() => {
    setIsSSR(false);
  }, []);

  useEffect(() => {
    if (!isSSR) {
      setPageContext({ ...props.pageContext });
    }
  }, [props.pageContext]);

  return (
    <PageContext.Provider
      value={{
        pageContext,
        setPageContext,
        pageExternalImages,
        setPageExternalImages,
        showCartDropdown,
        setShowCartDropdown
      }}
    >
      {props.children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext(PageContext);
