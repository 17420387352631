// Function to initialize BreadPayments once it's ready
function initBreadPayments(resolve, reject) {
  if (window && window.BreadPayments) {
    window.BreadPayments.setup({
      integrationKey: process.env.GATSBY_PUBLIC_BREADPAY_INTEGRATION_KEY
    });
    window.BreadPayments.on('INSTALLMENT:APPLICATION_DECISIONED', async (installmentResult) => {
      console.log('APPLICATION_DECISIONED', installmentResult);
    });
    window.BreadPayments.on('INSTALLMENT:APPLICATION_CHECKOUT', async (installmentResult) => {
      console.log('APPLICATION_CHECKOUT', installmentResult);
    });
    window.BreadPayments.init();
    return resolve();
  }
  reject();
}

// Function to wait for BreadPayments to be ready
export function waitForBreadPayments() {
  return new Promise((resolve, reject) => {
    try {
      if (window && window.BreadPayments) {
        initBreadPayments(resolve, reject);
      } else {
        // Check periodically if BreadPayments is available
        const intervalId = setInterval(() => {
          if (window.BreadPayments) {
            clearInterval(intervalId);
            initBreadPayments(resolve, reject);
          }
        }, 500);
      }
    } catch (e) {
      console.warn('Window is not defined');
      reject();
    }
  });
}
