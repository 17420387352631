import { convertObjectToString } from '../utils/convertValues';

/**
 * @description Get data from local storage
 * @param {string} key
 * @returns {string} The data
 */
export const getLocalStorageData = (key) => {
  const data = typeof window !== 'undefined' && localStorage.getItem(key);

  return data;
};

/**
 * @description Set data to local storage
 * @param {string} key
 * @param  data
 * @returns {void}
 */
export const setLocalStorageData = (key, data) => {
  if (data) {
    typeof window !== 'undefined' &&
      localStorage.setItem(
        key,
        data && Object.prototype.toString.call(data) === '[object Object]' && Object.keys(data)?.length > 0
          ? convertObjectToString(data)
          : data
      );
  }

  return;
};

/**
 * @description Unset data from local storage
 * @param {string} key
 * @returns {void}
 */
export const unsetLocalStorageData = (key) => {
  typeof window !== 'undefined' && localStorage.removeItem(key);

  return;
};
