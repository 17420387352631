import axios from 'axios';
import { useMainSWRConfig } from '../../useMainSWRConfig';

/**
 * @description Returns a Checkout.
 * @param {*} checkoutId
 * @returns {Promise} Promise object represents the result of the request
 */
export const getCheckout = async ({ checkoutId }) => {
  let endpoint = `/api/bigcommerce/checkouts/checkout/get-checkout?checkout_id=${checkoutId}`;
  endpoint += `&include=cart.line_items.physical_items.options,cart.line_items.digital_items.options,consignments.available_shipping_options,promotions.banners`;

  return await axios
    .get(endpoint)
    .then((res) => Promise.resolve(res))
    .catch((err) => Promise.reject(err));
};

/**
 * @description Change customer message to an existing checkout.
 * @param {*} checkoutId
 * @param {*} customerMessage
 * @returns {Promise} Promise object represents the result of the request
 */
export const updateCustomerMessage = async ({ checkoutId, customerMessage }) => {
  let endpoint = `/api/bigcommerce/checkouts/checkout/update-customer-message?checkout_id=${checkoutId}`;

  const body = {
    customer_message: customerMessage
  };

  return await axios
    .put(endpoint, body)
    .then((res) => Promise.resolve(res))
    .catch((err) => Promise.reject(err));
};

/**
 * @description SWR hook for fetching the checkout data
 * @param {*} checkoutId
 * @returns {Object} Checkout data
 */
export const useCheckout = async (checkoutId) => {
  let endpoint = checkoutId ? `/api/bigcommerce/checkouts/checkout/get-checkout?checkout_id=${checkoutId}` : null;

  const {
    data: checkout,
    error: checkoutError,
    isValidating: isCheckoutValidating,
    mutate: mutateCheckout
  } = useMainSWRConfig(endpoint);

  return {
    checkout,
    checkoutError,
    isCheckoutValidating,
    mutateCheckout
  };
};
