import axios from 'axios';

/**
 * Adds a new consignment to a checkout.
 *
 * Source: https://developer.bigcommerce.com/api-reference/b3A6MzU5MDQ0NzY-add-consignment-to-checkout
 *
 * @returns {Promise} Promise object represents the result of the request
 */
export const addConsignmentToCheckout = async ({ checkoutId, address, lineItems }) => {
  const endpoint = `/api/bigcommerce/checkouts/checkout-consignments/add-consignment-to-checkout?checkout_id=${checkoutId}&include=consignments.available_shipping_options`;

  const body = {
    address,
    line_items: lineItems
  };

  return await axios
    .post(endpoint, body)
    .then((res) => Promise.resolve(res))
    .catch((err) => Promise.reject(err));
};

/**
 * Updates an existing consignment. Address, line item IDs or the shipping option ID can be
 * updated using this endpoint.
 *
 * Source: https://developer.bigcommerce.com/api-reference/b3A6MzU5MDQ0Nzc-update-checkout-consignment
 *
 * @returns {Promise} Promise object represents the result of the request
 */
export const updateCheckoutConsignment = async ({
  checkoutId,
  consignmentId,
  address,
  lineItems,
  shippingOptionId
}) => {
  const endpoint = `/api/bigcommerce/checkouts/checkout-consignments/update-checkout-consignment?checkout_id=${checkoutId}&consignment_id=${consignmentId}&include=consignments.available_shipping_options`;

  const body = {
    address,
    line_items: lineItems,
    shipping_option_id: shippingOptionId
  };

  return await axios
    .put(endpoint, body)
    .then((res) => Promise.resolve(res))
    .catch((err) => Promise.reject(err));
};

/**
 * Removes an existing consignment from a checkout.
 *
 * Removing the last consigment will remove the Cart from the customer it is assigned to. Create
 * a new redirect url for the customer to access it again.
 *
 * Source: https://developer.bigcommerce.com/api-reference/b3A6MzU5MDQ0Nzg-delete-checkout-consignment
 *
 * @returns {Promise} Promise object represents the result of the request
 */
export const deleteCheckoutConsignment = async ({ checkoutId, consignmentId }) => {
  const endpoint = `/api/bigcommerce/checkouts/checkout-consignments/update-checkout-consignment?checkout_id=${checkoutId}&consignment_id=${consignmentId}`;

  return await axios
    .delete(endpoint)
    .then((res) => Promise.resolve(res))
    .catch((err) => Promise.reject(err));
};
