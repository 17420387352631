import { useEffect, useState } from 'react';

export function useScreen() {
  const [width, setWidth] = useState(0);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width ? width <= 768 : false;
  const isTablet = width ? width < 1024 : false;
  const isFullSize = width ? width >= 1280 : false;
  return {
    isMobile,
    isFullSize,
    isTablet,
    width
  };
}
